var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("step", { attrs: { active: _vm.active } }),
      _c(
        "div",
        { staticClass: "ui_realname" },
        [
          _c("h5", [_vm._v("实名认证：")]),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "ui_ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "法人姓名", prop: "legalName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入法人姓名" },
                    model: {
                      value: _vm.ruleForm.legalName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "legalName", $$v)
                      },
                      expression: "ruleForm.legalName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "法人性别", prop: "legalSex" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", size: "15" },
                      model: {
                        value: _vm.ruleForm.legalSex,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "legalSex",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "ruleForm.legalSex",
                      },
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { label: "不愿透露", value: "0" } },
                        [_vm._v("不愿透露")]
                      ),
                      _c(
                        "el-option",
                        {
                          attrs: {
                            label: _vm.$t("searchModule.male"),
                            value: "1",
                          },
                        },
                        [_vm._v("男")]
                      ),
                      _c(
                        "el-option",
                        {
                          attrs: {
                            label: _vm.$t("searchModule.female"),
                            value: "2",
                          },
                        },
                        [_vm._v("女")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "跨性别", value: "9" } },
                        [_vm._v("跨性别")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "居住地址", prop: "legalmanHomeAddr" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入法人真实居住地址" },
                    model: {
                      value: _vm.ruleForm.legalmanHomeAddr,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "legalmanHomeAddr", $$v)
                      },
                      expression: "ruleForm.legalmanHomeAddr",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.phone_number"),
                    prop: "legalMobile",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ui_realname_iphone" },
                    [
                      _c("el-input", {
                        staticStyle: { width: "70%" },
                        attrs: { placeholder: "请输入法人手机号" },
                        model: {
                          value: _vm.ruleForm.legalMobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "legalMobile", $$v)
                          },
                          expression: "ruleForm.legalMobile",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.mailbox"),
                    prop: "legalEmail",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入法人邮箱" },
                    model: {
                      value: _vm.ruleForm.legalEmail,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "legalEmail", $$v)
                      },
                      expression: "ruleForm.legalEmail",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "身份证号", prop: "legalIdCardNo" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入法人身份证号" },
                    model: {
                      value: _vm.ruleForm.legalIdCardNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "legalIdCardNo", $$v)
                      },
                      expression: "ruleForm.legalIdCardNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "证件有效期至", prop: "legalCardDeadLine" } },
                [
                  _c("el-date-picker", {
                    attrs: { type: "date", placeholder: "选择日期" },
                    model: {
                      value: _vm.ruleForm.legalCardDeadLine,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "legalCardDeadLine", $$v)
                      },
                      expression: "ruleForm.legalCardDeadLine",
                    },
                  }),
                  _c("span", { staticStyle: { margin: "0 10px" } }, [
                    _vm._v("或"),
                  ]),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "9999-12-31" },
                      model: {
                        value: _vm.ruleForm.legalCardDeadLine,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "legalCardDeadLine", $$v)
                        },
                        expression: "ruleForm.legalCardDeadLine",
                      },
                    },
                    [_vm._v("长期")]
                  ),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "", prop: "userId" } }, [
                _c("p", [_vm._v("身份证正反面照片 (支持jpg/png, 文件 < 2MB)")]),
                _c("div", { staticClass: "flex" }, [
                  _c("div", [
                    _c(
                      "div",
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              action: "/acb/2.0/tenant/cmb/imgUpload",
                              "show-file-list": false,
                              "on-change": _vm.handleChange,
                              "on-success": _vm.handleAvatarSuccess,
                              "before-upload": _vm.beforeAvatarUpload,
                              "http-request": _vm.uploadPicture,
                              data: { type: 1 },
                            },
                          },
                          [
                            _vm.imageUrl
                              ? _c("img", {
                                  staticClass: "avatar",
                                  attrs: { src: _vm.imageUrl },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ]
                        ),
                        _c("span", [_vm._v("身份证头像页")]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", [
                    _c(
                      "div",
                      { staticStyle: { "margin-left": "20px" } },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              action: "/acb/2.0/tenant/cmb/imgUpload",
                              "show-file-list": false,
                              "on-change": _vm.handleChange2,
                              "on-success": _vm.handleAvatarSuccess2,
                              "before-upload": _vm.beforeAvatarUpload,
                              "http-request": _vm.uploadPicture,
                              data: { type: 2 },
                            },
                          },
                          [
                            _vm.imageUrl2
                              ? _c("img", {
                                  staticClass: "avatar",
                                  attrs: { src: _vm.imageUrl2 },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ]
                        ),
                        _c("span", [_vm._v("身份证国徽页")]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "公司股东", prop: "legalShareholdFlag" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.legalShareholdFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "legalShareholdFlag", $$v)
                        },
                        expression: "ruleForm.legalShareholdFlag",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("法人")]),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("他人")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.ruleForm.legalShareholdFlag === 0
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "股东姓名",
                            prop: "shareholderName",
                            rules: {
                              required: true,
                              message: "股东姓名不能为空",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入股东姓名" },
                            model: {
                              value: _vm.ruleForm.shareholderName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "shareholderName", $$v)
                              },
                              expression: "ruleForm.shareholderName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "身份证号",
                            prop: "shareholderCertno",
                            rules: {
                              required: true,
                              message: "身份证号不能为空",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入身份证号" },
                            model: {
                              value: _vm.ruleForm.shareholderCertno,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "shareholderCertno", $$v)
                              },
                              expression: "ruleForm.shareholderCertno",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "证件有效期至",
                            prop: "shareholderCertExpire",
                            rules: {
                              required: true,
                              message: "证件有效期不能为空",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: { type: "date", placeholder: "选择日期" },
                            model: {
                              value: _vm.ruleForm.shareholderCertExpire,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "shareholderCertExpire",
                                  $$v
                                )
                              },
                              expression: "ruleForm.shareholderCertExpire",
                            },
                          }),
                          _c("span", { staticStyle: { margin: "0 10px" } }, [
                            _vm._v("或"),
                          ]),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "9999-12-31" },
                              model: {
                                value: _vm.ruleForm.shareholderCertExpire,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "shareholderCertExpire",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.shareholderCertExpire",
                              },
                            },
                            [_vm._v("长期")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "受益人", prop: "legalBenefitFlag" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.legalBenefitFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "legalBenefitFlag", $$v)
                        },
                        expression: "ruleForm.legalBenefitFlag",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("仅法人"),
                      ]),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("多人")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.ruleForm.legalBenefitFlag === 0
                ? _c(
                    "div",
                    [
                      _vm._l(
                        _vm.ruleForm.mechantBenefitList,
                        function (benefit, index) {
                          return _c(
                            "div",
                            { key: "benefit" + index },
                            [
                              index !== 0 ? _c("el-divider") : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "受益人姓名",
                                    prop:
                                      "mechantBenefitList." + index + ".name",
                                    rules: {
                                      required: true,
                                      message: "受益人姓名不能为空",
                                      trigger: "blur",
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex",
                                      staticStyle: { "align-items": "center" },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入受益人姓名",
                                        },
                                        model: {
                                          value: benefit.name,
                                          callback: function ($$v) {
                                            _vm.$set(benefit, "name", $$v)
                                          },
                                          expression: "benefit.name",
                                        },
                                      }),
                                      index !== 0
                                        ? _c("i", {
                                            staticClass: "el-icon-remove",
                                            staticStyle: {
                                              "font-size": "24px",
                                              "margin-left": "10px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteBeneficiary(
                                                  benefit,
                                                  index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "居住地址",
                                    prop:
                                      "mechantBenefitList." +
                                      index +
                                      ".manHomeAddr",
                                    rules: {
                                      required: true,
                                      message: "居住地址不能为空",
                                      trigger: "blur",
                                    },
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入居住地址" },
                                    model: {
                                      value: benefit.manHomeAddr,
                                      callback: function ($$v) {
                                        _vm.$set(benefit, "manHomeAddr", $$v)
                                      },
                                      expression: "benefit.manHomeAddr",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "身份证号",
                                    prop:
                                      "mechantBenefitList." +
                                      index +
                                      ".IdCardNo",
                                    rules: {
                                      required: true,
                                      message: "身份证号不能为空",
                                      trigger: "blur",
                                    },
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入身份证号" },
                                    model: {
                                      value: benefit.IdCardNo,
                                      callback: function ($$v) {
                                        _vm.$set(benefit, "IdCardNo", $$v)
                                      },
                                      expression: "benefit.IdCardNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "证件有效期至",
                                    prop:
                                      "mechantBenefitList." +
                                      index +
                                      ".cardDeadLine",
                                    rules: {
                                      required: true,
                                      message: "证件有效期不能为空",
                                      trigger: "blur",
                                    },
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                    },
                                    model: {
                                      value: benefit.cardDeadLine,
                                      callback: function ($$v) {
                                        _vm.$set(benefit, "cardDeadLine", $$v)
                                      },
                                      expression: "benefit.cardDeadLine",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { margin: "0 10px" } },
                                    [_vm._v("或")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "9999-12-31" },
                                      model: {
                                        value: benefit.cardDeadLine,
                                        callback: function ($$v) {
                                          _vm.$set(benefit, "cardDeadLine", $$v)
                                        },
                                        expression: "benefit.cardDeadLine",
                                      },
                                    },
                                    [_vm._v("长期")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", icon: "el-icon-plus" },
                              on: {
                                click: function ($event) {
                                  return _vm.addBeneficiary()
                                },
                              },
                            },
                            [_vm._v(" 添加更多受益人 ")]
                          ),
                          _vm.ruleForm.mechantBenefitList.length
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveBenefit()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm", "next")
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }