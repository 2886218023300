var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui_step" },
    [
      _c("h5", [_vm._v("流程总览")]),
      _c(
        "el-steps",
        {
          attrs: {
            active: _vm.active,
            "finish-status": "success",
            "align-center": "",
          },
        },
        [
          _c("el-step", { attrs: { title: "认证信息" } }),
          _c("el-step", { attrs: { title: "营业信息" } }),
          _c("el-step", { attrs: { title: "账户信息" } }),
          _c("el-step", { attrs: { title: "签订协议" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }